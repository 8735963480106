blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}
.email_editor_wrapper table,
.email_editor_wrapper th,
.email_editor_wrapper td {
  border: 1px solid black;
}
.email_editor_wrapper table {
  border-collapse: collapse;
}
.email_editor_wrapper button {
  background-color: white;
  border: none;
  opacity: 0.5;
}

.email_editor_wrapper .btnActive {
  opacity: 1;
}
.email_editor_wrapper .editor-wrapper {
  font-family: "Helvetica", "Arial", sans-serif;
  border-radius: 10px;
  background: #ffffff;
  /* box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff; */
  min-height: 400px;
  min-width: 100%;
  width: fit-content;
  height: fit-content;
  padding: 20px 10px;
}
.email_editor_wrapper table {
  width: 100%;
}
.email_editor_wrapper td {
  height: 50px;
  padding: 0 5px;
}
.popup-wrapper {
  display: inline;
  position: relative;
}
.popup {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;
}
.email_editor_wrapper button {
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
[data-slate-node="element"] {
  margin: 10px 0;
}
html {
  scroll-behavior: smooth;
}
