.toolbar {
  border-radius: 10px;
  background: #ffffff;
  /* box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff; */
  /* margin: 5% 0; */
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 10px;
  row-gap: 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.toolbar-grp > * {
  margin-right: 10px;
  cursor: pointer;
}
.toolbar-grp {
  margin: 0 10px;
}
select {
  height: 30px;
  border: none;
  width: 6.9rem;
}
